<template>
  <div>
    <b-card title="Araç Bilgisi">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Marka"
            label-for="brand"
          >
            <b-form-input
              id="brand"
              v-model="service.car_brand"
              placeholder="Marka"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Model"
            label-for="model"
          >
            <b-form-input
              id="model"
              v-model="service.car_model"
              placeholder="Model"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Model Yılı"
            label-for="myear"
          >
            <validation-provider
              #default="{ errors }"
              name="Model Yılı"
              rules="required"
            >
              <cleave
                id="myear"
                v-model="service.myear"
                class="form-control"
                :raw="false"
                :options="options.myear"
                placeholder="Model Yılı"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Plaka"
            label-for="license_plate"
          >
            <validation-provider
              #default="{ errors }"
              name="Plaka"
              rules="required"
            >
              <b-form-input
                id="license_plate"
                v-model="service.license_plate"
                placeholder="Plaka"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Güncel Km"
            label-for="km"
          >
            <validation-provider
              #default="{ errors }"
              name="Güncel Km"
              rules="required"
            >
              <b-form-input
                id="km"
                v-model="service.km"
                placeholder="Güncel Km"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Şase"
            label-for="chassis"
          >
            <b-form-input
              id="chassis"
              v-model="service.chassis"
              placeholder="Şase"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Muayene Tarihi"
            label-for="muayene"
          >
            <b-form-datepicker
              id="muayene"
              v-model="service.muayene"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Kasko Bitiş Tarihi"
            label-for="kasko"
          >
            <b-form-datepicker
              id="kasko"
              v-model="service.kasko"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Sigorta Bitiş Tarihi"
            label-for="trafik"
          >
            <b-form-datepicker
              id="trafik"
              v-model="service.trafik"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Egzoz Muayene Tarihi"
            label-for="egzoz"
          >
            <b-form-datepicker
              id="egzoz"
              v-model="service.egzoz"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-center">
        <b-button
          v-b-toggle.sidebar-invoice-add-new-car
          variant="danger"
        >
          <FeatherIcon icon="EditIcon" />
          Araç Kartını Güncelle
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BFormGroup, BButton, BFormDatepicker, VBToggle,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'CarForm',
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    BFormDatepicker,
    ValidationProvider,
    Cleave,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      required,
      options: {
        myear: {
          blocks: [4],
          numericOnly: true,
        },
        km: {
          numericOnly: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
