<template>
  <div>
    <search-code />
    <result-code :service-id="serviceId" />
  </div>
</template>

<script>
import SearchCode from '@/views/Service/Coupon/components/Search.vue'
import ResultCode from '@/views/Service/Coupon/components/Result.vue'

export default {
  name: 'Index',
  components: {
    SearchCode,
    ResultCode,
  },
  props: {
    serviceId: {
      // eslint-disable-next-line no-bitwise,vue/require-prop-type-constructor
      type: String | Number,
      default: null,
    },
  },
}
</script>
