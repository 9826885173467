<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>İş Emirleri</b-card-title>
        <b-button
          id="addBtn"
          variant="primary"
          @click="showModal(null)"
        >
          <feather-icon icon="PlusIcon" />Oluştur
        </b-button>
      </b-card-header>
      <b-table-simple
        v-if="dataList.length >0"
        responsive
        small
      >
        <b-thead>
          <b-tr>
            <b-th
              v-for="(row,index) in tableRow"
              :key="index"
            >
              {{ row }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row,index) in dataList"
            :key="index"
          >
            <template
              v-for="(column,cIndex) in row"
            >
              <b-td
                v-if="cIndex !== 'id'"
                :key="cIndex"
              >
                {{ column }}
              </b-td>
            </template>
            <b-td style="width: 150px">
              <b-button
                class="mr-1"
                size="sm"
                variant="flat-warning"
                @click="showModal(row.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="userData.id_com_user_type === '1'"
                size="sm"
                variant="flat-danger"
                @click="removeData(row.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-alert
        v-else
        show
        variant="info"
        class="m-2"
      >
        <div class="alert-body text-center">
          Kaydedilmiş iş emri bulunmuyor.
        </div>
      </b-alert>
    </b-card>
    <b-modal
      v-model="modalData.status"
      title="İş Emri"
      centered
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      size="xl"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="İşlem Numarası"
            for="wnumber"
          >
            <b-form-input
              id="wnumber"
              v-model="workRow.wnumber"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="İşlem"
            for="process"
          >
            <b-form-input
              id="process"
              v-model="workRow.process"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Tahakkuk"
            for="accrual"
          >
            <b-form-input
              id="accrual"
              v-model="workRow.accrual"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="İşlem İçeriği"
            for="content"
          >
            <b-form-textarea
              id="content"
              v-model="workRow.content"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Miktar"
            for="amount"
          >
            <b-form-input
              id="amount"
              v-model="workRow.amount"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Birim"
            for="unit"
          >
            <b-form-input
              id="unit"
              v-model="workRow.unit"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="İndirim / İskonto"
            for="discount"
          >
            <b-form-input
              id="discount"
              v-model="workRow.discount"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Fiyat"
            for="price"
          >
            <b-form-input
              id="price"
              v-model="workRow.price"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Vergi"
            for="tax"
          >
            <b-form-input
              id="tax"
              v-model="workRow.tax"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Toplam Tutar"
            for="total"
          >
            <b-form-input
              id="total"
              v-model="workRow.total"
              placeholder="Giriniz.."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          id="cancelForm"
          variant="secondary"
          @click="modalData.status=false"
        >
          <feather-icon icon="XIcon" /> İptal
        </b-button>
        <b-button
          id="saveForm"
          variant="primary"
          :disabled="savingData"
          @click="saveData"
        >
          <b-spinner
            v-if="savingData"
            small
          />
          <feather-icon icon="SaveIcon" /> Kaydet
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BButton,
  BModal,
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ServiceWorks',
  components: {
    BAlert,
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      tableRow: {
        wnumber: 'İşlem No',
        accrual: 'Tahakkuk',
        process: 'İşlem',
        content: 'İşlem Açıklaması',
        control: '',
      },
      modalData: {
        status: false,
        id_service_tracing_works: null,
      },
      savingData: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceTracing/getService']
    },
    workRow() {
      return this.$store.getters['serviceTracingWorks/dataItem']
    },
    dataList() {
      return this.$store.getters['serviceTracingWorks/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('serviceTracingWorks/getDataList', {
        select: [
          'com_service_tracing_works.id                      AS id',
          'com_service_tracing_works.wnumber                 AS wnumber',
          'com_service_tracing_works.accrual                 AS accrual',
          'com_service_tracing_works.process                 AS process',
          'com_service_tracing_works.content                 AS content',
        ],
        where: {
          'com_service_tracing_works.id_com_service_tracing': this.$route.params.id,
        },
      })
    },
    showModal(idServiceWorks = null) {
      if (idServiceWorks != null) {
        this.$store.dispatch('serviceTracingWorks/getDataItem', idServiceWorks)
        this.modalData.id_service_tracing_works = idServiceWorks
      } else {
        this.$store.commit('serviceTracingWorks/RESET_DATA_ITEM')
        this.dataItem.id_service_tracing_works = idServiceWorks
      }
      this.modalData.status = true
    },
    saveData() {
      this.savingData = true
      this.modalData.status = false
      this.workRow.id_com_service_tracing = this.dataItem.id
      this.$store.dispatch('serviceTracingWorks/saveData', this.workRow).then(response => {
        if (response.status) {
          this.showAlert({
            variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: response.message,
          })
          this.modalData.status = false
          this.getDataList()
        } else {
          this.showAlert({
            variant: 'error', title: 'İşlem Başarısız!', icon: 'CheckIcon', text: response.message,
          })
        }
        this.savingData = false
      })
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceTracingWorks/deleteData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    showAlert(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          icon: 'BellIcon',
          text: data.text,
          variant: data.variant,
        },
      })
    },
  },
}
</script>
