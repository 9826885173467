<template>
  <b-card title="Randevu Bilgisi">
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Müşteri</strong>
        <span>{{ service.customer_name }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Araç</strong>
        <span>{{ service.car_brand }} {{ service.car_model }} {{ service.license_plate }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Şase</strong>
        <span>{{ service.chassis? service.chassis : '-' }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Muayene Tarihi</strong>
        <span>{{ service.muayene? moment(service.muayene).format('LL') : '-' }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Kasko Bitiş Tarihi</strong>
        <span>{{ service.kasko? moment(service.kasko).format('LL') : '-' }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Sigorta Bitiş Tarihi</strong>
        <span>{{ service.trafik? moment(service.trafik).format('LL') : '-' }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Egzoz Muayene Tarihi</strong>
        <span>{{ service.egzoz? moment(service.egzoz).format('LL') : '-' }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Servis Danışmanı</strong>
        <span>{{ service.service_user }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Randevu</strong>
        <span>{{ moment(service.appointment_date).format('LL') }} {{ service.appointment_time }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Yapılacak İşlem</strong>
        <span>
          <div
            v-for="(type,index) in service.service_type"
            :key="index"
          >
            {{ type }}
          </div>
        </span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Müşteri Talebi</strong>
        <span>
          {{ service.customer_request }}
        </span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <strong>Randevu Durumu</strong>
        <span>
          {{ service.service_status }}
        </span>
      </b-list-group-item>
      <b-list-group-item
        v-if="service.cancel_content"
        class="d-flex justify-content-between text-danger"
      >
        <strong>İptal Açıklaması</strong>
        <span>
          {{ service.cancel_content }}
        </span>
      </b-list-group-item>
    </b-list-group>
    <div class="text-center mt-2">
      <b-button
        variant="success"
        :disabled="service.appointment_date < moment() || service.id_com_service_status > 2 || service.customer_kvkk !== '1'"
        @click="acceptedService"
      >
        Kaydet ve Randevuyu Tamamla
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Appointment',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
  methods: {
    acceptedService() {
      this.$store.dispatch('serviceTracing/acceptedService', this.service)
    },
  },
}
</script>
