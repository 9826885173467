<template>
  <b-card
    v-if="itemData.error === false"
    title="Kampanya"
  >
    <b-row>
      <b-col
        md="4"
        cols="12"
      >
        <p class="mb-0">
          İndirim Kodu:
        </p>
        <h3>{{ itemData.code }}</h3>
        <p class="mb-0">
          Durum:
        </p>
        <p class="text-success font-weight-bolder">
          Kalan {{ moment(itemData.edate).diff(moment(),'days') }} Gün
        </p>
        <b-button
          variant="success"
          :disabled="itemData.used"
          @click="useCode"
        >
          <FeatherIcon icon="CheckIcon" />
          Kodu Kullan
        </b-button>
      </b-col>
      <b-col
        md="8"
        cols="12"
      >
        <table-code />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import TableCode from '@/views/Service/Coupon/components/result/Table.vue'

export default {
  name: 'Result',
  components: {
    BCard, BRow, BCol, BButton, TableCode,
  },
  props: {
    serviceId: {
      // eslint-disable-next-line no-bitwise,vue/require-prop-type-constructor
      type: String | Number,
      default: null,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['serviceCoupons/getCoupon']
    },
  },
  methods: {
    useCode() {
      const params = {
        id: this.itemData.id,
        id_com_service_tracing: this.serviceId,
      }
      this.$store.dispatch('serviceCoupons/useCoupon', params)
    },
  },
}
</script>
