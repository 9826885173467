<template>
  <b-table-simple>
    <b-tr>
      <b-th>Müşteri Adı</b-th>
      <b-td>{{ itemData.customer }}</b-td>
    </b-tr>
    <b-tr>
      <b-th>Telefon</b-th>
      <b-td>{{ itemData.phone }}</b-td>
    </b-tr>
    <b-tr>
      <b-th>İndirim İçeriği</b-th>
      <b-td>{{ itemData.coupon_type }}</b-td>
    </b-tr>
    <b-tr>
      <b-th>Oluşturma</b-th>
      <b-td>{{ itemData.username }} - {{ moment(itemData.created).format('LLL') }}</b-td>
    </b-tr>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BTr, BTh, BTd,
} from 'bootstrap-vue'

export default {
  name: 'Table',
  components: {
    BTableSimple, BTr, BTh, BTd,
  },
  computed: {
    itemData() {
      return this.$store.getters['serviceCoupons/getCoupon']
    },
  },
}
</script>
