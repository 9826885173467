<template>
  <b-row v-if="service.id">
    <b-col
      cols="12"
    >
      <b-alert
        v-if="service.appointment_date < moment() || service.id_com_service_status > 2"
        variant="danger"
        show
      >
        <div class="alert-body text-center p-2">
          <FeatherIcon
            icon="InfoIcon"
            size="24"
          />
          <p>İşlem Yapılmaz! Servis randevusu kapatılmış.</p>
        </div>
      </b-alert>
    </b-col>
    <b-col cols="12">
      <!--      <bcs-campaign />-->
      <b-card title="Müşteri Bilgileri">
        <customer-card />
      </b-card>
    </b-col>
    <b-col cols="12">
      <coupon :service-id="service.id" />
    </b-col>
    <b-col
      v-if="service.customer_kvkk !== '1'"
      cols="12"
    >
      <kvkk-inline
        v-if="refreshData"
        :customer-id="service.id_com_customer"
        :refresh-data="refreshData"
      />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <car-form />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <appointment />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import CustomerCard from '@/views/Service/View/CustomerCard.vue'
import CarForm from '@/views/Service/View/CarForm.vue'
import Appointment from '@/views/Service/View/Appointment.vue'
import Coupon from '@/views/Service/Coupon/index.vue'
// import BcsCampaign from '@/views/Service/components/BcsCampaign.vue'
import KvkkInline from '@/layouts/components/common/KvkkInline.vue'

export default {
  name: 'ServiceView',
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    CustomerCard,
    CarForm,
    Appointment,
    Coupon,
    // BcsCampaign,
    KvkkInline,
  },
  props: {
    refreshData: {
      type: Function,
      default: null,
    },
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
}
</script>
